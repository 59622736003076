.dashboard {
    display: flex;
    flex-direction: column;
    gap: 28px;
    width: 100%;
    box-sizing: border-box;
}

.metrics-row {
    width: 100%;
    margin: 16px 0px;

    .col-lg-3 {
        padding: 0;
    }
}

.tabs {
    margin-top: 0px;
}

.small-text {
    font-size: 14px;
}

.tabs.only-two-active {
    .tabs-links {
        button:nth-child(2) {
            pointer-events: none;
            opacity: 0.5;
        }
        button:nth-child(3) {
            pointer-events: none;
            opacity: 0.5;
        }
    }
}
