.container-input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.required {
    color: var(--red);
}

.input-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.checkbox-wrapper {
    display: flex;
}

.text-align-right {
    text-align: right;
}

.error-label {
    color: var(--color-error);
    font-size: 12px;
    height: 18px;
}

.form-checkbox {
    height: 18px !important;
    cursor: pointer;
}

.error-msg {
    border: 2px solid var(--color-error) !important;
    border-radius: 8px;
    //padding: 2px;
}

.square {
    width: 45px !important;
    height: 45px !important;
    border: solid 1px var(--color-border-digit);
    box-sizing: border-box;
    border-radius: 5px !important;
}

.form-control {
    border-radius: 99px;
}

.input {
    font-size: 12px;
    height: 38px;
}

.textarea {
    min-height: 112px;
    height: 112px;
    border-radius: 6px;
}

.date-input {
    display: flex;
    direction: rtl;
    font-size: 12px;
    height: 38px;
    width: 100%;
    border-radius: 99px;
    border: solid 1px var(--color-border-digit);
    padding: 0 24px 0 45px !important;
    color: var(--user-title);
}

.custom-datepicker {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .date-label {
        font-size: 12px;
        margin-bottom: 3px;
    }

    .date-with-icon {
        display: flex;
        align-items: center;
        z-index: 0;
        position: relative;
        ß .react-datepicker-wrapper {
            width: 100%;
        }
    }

    .icon {
        color: var(--input-icon);
        position: absolute;
        left: 12px; /* position the icon to the left of the input */
        z-index: 1; /* make sure the icon is on top of the input */
    }

    .input-icon-separator {
        -webkit-align-self: stretch;
        align-self: stretch;
        height: 20px;
        margin-top: 8px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        border-right: 1px solid var(--input-icon);
        box-sizing: border-box;
        position: absolute;
        left: 36px;
        z-index: 1;
    }
}

.react-datepicker-popper {
    z-index: 3 !important;
}
