.event-container {
    border: 2px solid white;
    border-radius: 10px;
    box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.15);
    width: 98%;
    transition: background-color 0.3s ease, border 0.3s ease;

    .event-wrapper {
        direction: rtl;
        background-color: rgba(169, 156, 226, 1); // Default color
        border-radius: 10px;
        padding: 1em;
        display: flex;
        position: relative;
        overflow: hidden;
        transition: background-color 0.3s ease, box-shadow 0.3s ease;
    }

    .event-icon {
        position: absolute;
        top: 60%;
        left: 60%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.4;
    }

    .event-profile-details {
        display: flex;
        gap: 1em;
        width: 40%;

        .event-name-role {
            .event-profile-name {
                font-size: 20px;
                font-weight: 600;
                text-align: right;
            }
            .event-profile-role {
                font-size: 16px;
                font-weight: 400;
                text-align: right;
            }
        }
    }

    .event-details {
        display: grid;
        align-items: end;

        .event-date-type {
            display: flex;
            justify-content: space-between;
            flex-grow: 1;

            p {
                margin: 0;
                font-size: 20px;
                font-weight: 600;
                text-align: right;

                .event-name {
                    margin-right: 5px;
                }

                .dot {
                    margin: 0 5px;
                    font-size: 1rem;
                }

                .formatted-date {
                    margin-left: 5px;
                }
            }

            .days-left {
                font-size: 16px;
                font-weight: 400;
                text-align: right;
            }
        }

        .event-details-buttons {
            display: flex;
            justify-content: space-between;
            visibility: hidden;
            .event-button {
                background: rgba(63, 47, 132, 1);
                border: 1px solid rgba(63, 47, 132, 1);
                border-radius: 20px;
                color: white;
                cursor: pointer;
                width: 100px;
                height: 30px;
                font-size: 11.2px;
                font-weight: 400;
                line-height: 11.2px;
                text-align: right;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 0.5em;
            }

            /* After clicking, buttons shrink and show only icons */
            .event-button.icon-only {
                width: 8%;
                padding: 0;
            }

            .event-button.icon-only .button-text {
                display: none;
            }

            /* On hover, expand the button */
            .event-button.icon-only:hover {
                width: auto;
                padding: 0 12px;
                border-radius: 20px;
            }

            .event-button.icon-only:hover .button-text {
                display: inline-block;
            }

            /* When a button is clicked, it turns green */
            .event-button.selected {
                background: rgba(54, 179, 126, 1); // Green
                border: 1px solid rgba(54, 179, 126, 1);
            }
        }
    }

    .event-profile-picture {
        width: 67px;
        height: 67px;
        object-fit: cover;
        border-radius: 50%;
    }
}

/* When an action is selected, change only that event's background */
.event-wrapper.event-activated {
    background-color: rgba(240, 238, 246, 1); // Light grayish purple
}
.event-profile-picture-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 67px;
    height: 67px;
    border-radius: 50%;
    background: rgba(200, 200, 200, 0.3); // Background in front of SVG (adjust as needed)
}
