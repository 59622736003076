.who-liked-viewed {
    background: rgba(246, 245, 250, 1);
    border-radius: 12px;
    width: calc(95% / 3);
    padding: 16px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    position: relative;
    display: flex;
    flex-direction: column;
}

.header-WhoLikedViewed {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

.user-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    max-height: 300px;
    padding-right: 5px;
}

/* User Row */
.user-item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border-bottom: 1px solid #eee;
}

/* Alternate background for odd/even rows */
.user-item:nth-child(odd) {
    background: rgba(228, 224, 241, 1);
}

.user-item:nth-child(even) {
    background: inherit;
}

/* Profile Image */
.profile-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

/* Name & Role */
.user-info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

/* Icons */
.icons {
    display: flex;
    gap: 5px;
    align-items: center;
}

.comment-icon {
    color: gray;
    width: 18px;
    height: 18px;
}

.heart-icon {
    color: red;
    width: 18px;
    height: 18px;
}

/* No users message */
.no-users {
    text-align: center;
    color: #999;
}
