.side-bar {
    position: sticky;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.65);
    width: 80px;
    right: 0;
    height: 100%;
    box-sizing: border-box;
    padding: 20px 0;
    box-shadow: 0 4px 24px 0 rgba(24, 41, 47, 0.1);
    z-index: 2;
    gap: 7px;
    justify-content: space-between;
    align-items: center;

    .nav {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        color: var(--title-grey-color);

        .link {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            height: 42px;
            width: 42px;
            box-sizing: border-box;
            border-radius: 5px;
            position: relative;

            &:hover {
                .desc {
                    display: flex;
                }
            }

            .desc {
                display: none;
                position: absolute;
                width: max-content;
                right: calc(100% + 10px);
                padding: 8px;
                color: var(--white);
                background-color: var(--primary);
                border-radius: 8px;
            }
        }
    }
    .header-content {
        display: grid;
        justify-items: center;
    }
    .greeting-text {
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        margin: 12px 0 0 0;
    }
    .user-name {
        font-size: 13px;
        font-weight: 700;
        line-height: 13px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        margin-bottom: 12.8px;
    }
    .header-logo {
        max-height: 38px;
    }
}
