.avatar-group {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-weight: 600;
    color: var(--color-title);
}

.avatar {
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0);
    border: 1.7px solid var(--white);
    border-radius: 50%;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.14);
    box-sizing: border-box;
}

.initials-avatar {
    position: absolute;
    border: 2px solid var(--white);
}
