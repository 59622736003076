.filter-header {
    display: flex;
    justify-content: flex-end;
    padding: 21px 21px 0;
}

.filter-separator {
    height: 1px;
    background-color: #08080825;
    margin: 20px 0;
    width: 100%
}

.filter-body {
    padding: 0 21px 21px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    .filters {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        align-items: center;
        height: 100%;
        width: 100%;
    }
}

.filter-title {
    padding: 11px;
    font-size: 18px;
    font-weight: 600;
    color: var(--title-grey-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.form-label {
    margin-bottom: 3px;
    font-size: 12px;
}

.size-default {
    width: 300px;
    box-sizing: border-box;
}

.checkbox-with-label {
    display: flex;
    height: 100%;
    align-items: center;
    gap: 10px;

    .checkbox-label {
        height: 100%;
        display: flex;
        flex: 1;
        justify-content: flex-start;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.5;
        color: var(--title-grey-color);
    }
}
