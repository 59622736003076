.store {
    margin: 30px 0 0;
    display: flex;
    flex-direction: column;
    gap: 28px;
    width: 100%;
    box-sizing: border-box;

    .management {
        display: flex;
        flex-direction: column;
        gap: 28px;
        width: 100%;
        box-sizing: border-box;
    }

    .steps-tabs {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;
    }
}

.table-text-icon {
    display: flex;
    gap: 20px;
    cursor: pointer;
    text-align: left;
}

.variant {
    display: flex;
    gap: 16px;
    position: relative;
    align-items: center;
}

.float-variant-button {
    width: 40px;
    height: 38px;
    padding: 0 !important;
    border-radius: 50%;
    left: -54px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.float-button {
    width: 40px;
    height: 38px;
    padding: 0 !important;
    border-radius: 50%;
    position: absolute;
    left: -54px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.step-footer {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding-top: 16px;

    .save-and-cancel {
        display: flex;
        align-items: center;
        gap: 16px;
    }
}
