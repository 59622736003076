@font-face {
    font-family: Rubik-Regular;
    font-weight: normal;
    src: url('./rubik/Rubik-Regular.ttf');
}

@font-face {
    font-family: Rubik;
    font-weight: normal;
    src: url('./rubik/Rubik-Regular.ttf');
}

@font-face {
    font-family: Rubik-Medium;
    font-weight: normal;
    src: url('./rubik/Rubik-Medium.ttf');
}

@font-face {
    font-family: Rubik-SemiBold;
    font-weight: normal;
    src: url('./rubik/Rubik-Bold.ttf');
}
