.verify-phone {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;


  .welcome-title {
    font-size: 24px;
    //font-family: Rubik-SemiBold, Rubik-Regular;
    line-height: 1.5;
    color: var(--title-grey-color)
  }

  .welcome-description {
    font-size: 14px;
    line-height: 1.5;
    //font-family: Rubik-Regular;
    color: var(--title-grey-color)
  }

  .phone-input {
    margin: 24px 0;
  }

  .logout-button {
    margin-top: 10px;
  }
}

