$card-bg: #ffffff;
$shadow-color: rgba(0, 0, 0, 0.1);
$green: #24b47e;
$red: #ff6b6b;
$text-color: #1d1b4d;

.budget-card {
    background: $card-bg;
    padding: 10px;
    border-radius: 20px;
    width: 40%;
    box-shadow: 0 5px 10px $shadow-color;
    text-align: right;
    direction: rtl;

    .header-BudgetCard {
        display: flex;
        justify-content: space-between;
        font-size: 20px;
        font-weight: bold;
        justify-content: space-between;
        .icon {
            font-size: 22px;
            opacity: 0.6;
        }

        .title {
            h2 {
                font-weight: 600;
                font-size: 20.26px;
                text-align: right;
            }

            .subtitle {
                font-size: 14px;
                color: gray;
                text-decoration: underline;
            }
        }
    }

    .budget-content {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding-top: 10px;

        .budget-item {
            text-align: center;

            .amount {
                font-size: clamp(1.5rem, 2vw, 2.5rem);
                font-weight: bold;
            }

            .green {
                color: $green;
            }

            .red {
                color: $red;
            }

            .label {
                font-size: 14px;
                color: $text-color;
            }
        }

        .divider {
            width: 1px;
            height: 50px;
            background: rgba(0, 0, 0, 0.2);
        }
    }
}
