
.tabs {
  display: flex;
  align-items: center;
  padding: 0;

  .nav-pills .nav-link.active {
    background-color: transparent;
    border: unset;
    box-shadow: unset;
    color: var(--primary);
    border-radius: unset;
    padding: 0;
  }

  .tabs-links {
    margin: 0 !important;
    padding: 0;
    display: flex;
    gap: 12px;
  }
}

@media (max-width: 450px) {
  .booking {
    .tabs {
      margin-top: 50px;
    }
  }
}
