.product-card-post {
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;

    .product-image {
        position: relative;
        height: 200px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .timestamp {
            position: absolute;
            top: 8px;
            left: 8px;
            color: #fff;
            font-size: 12px;
            padding: 4px 6px;
            border-radius: 4px;
        }

        .discount-badge {
            position: absolute;
            top: 8px;
            right: 8px;
            color: #fff;
            font-size: 12px;
            font-weight: bold;
            padding: 6px 10px;
            border-radius: 50%;
        }
    }

    .product-details {
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;

        .shopping-icon {
            color: #666;
            width: 24px;
            height: 24px;
        }

        .pricing {
            text-align: center;
            .current-price {
                font-size: 18px;
                font-weight: bold;
            }
            .original-price {
                font-size: 14px;
                color: #888;
                text-decoration: line-through;
            }
        }

        .availability {
            padding: 6px 12px;
            font-size: 12px;
            font-weight: bold;
            border-radius: 15px;
            border: none;
            transition: all 0.3s ease;

            &.available {
                color: #fff;
            }

            &.not-available {
                color: #fff;
            }
        }
    }
}
