.home {
    display: flex;
    height: 50%;
    overflow: hidden;
    gap: 16px;

    .welcome-feed {
        display: flex;
        padding: 0 32px;
        background-color: rgba(104, 224, 240, 0.24);
        border-radius: 6px;
        justify-content: space-between;
        height: 100px;
        margin: 16px 0;

        .main-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .welcome {
                font-size: 21px;
                font-weight: 600;
                margin: 0;
                color: var(--primary);
            }

            .what-to-do {
                font-size: 15px;
                margin: 0;
                font-weight: 600;
                line-height: 1.6;
                color: var(--title-grey-color);
            }

            .welcome-buttons {
                display: flex;
                gap: 16px;
            }
        }

        .feed-welcome-image {
            object-fit: contain;
            height: 100%;
            padding-right: 32px;
        }
    }

    .posts {
        display: flex;
        flex-direction: column;
        padding: 1em;
        gap: 24px;
        overflow: hidden;
    }

    .posts-wheel {
        overflow-y: scroll;
        height: 100%;
        width: 100%;
        direction: ltr;

        &::-webkit-scrollbar {
            width: 20px;
        }

        &::-webkit-scrollbar-track {
            background: #e9e9f0;
            border: 6px solid white;
        }
        &::-webkit-scrollbar-thumb {
            background: rgba(17, 8, 53, 1);
            border-radius: 40px;
        }
    }

    .post-wheel-container {
        background-color: white;
        padding-top: 16px;
        width: 60%;
        overflow: hidden;
        border-radius: 16px;
        padding: 12px 24px 0 12px;

        .postsTitle {
            font-weight: 600;
            font-size: 32px;
            line-height: 38.4px;
        }

        .buttons-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 16px;

            .buttons-wrapper {
                display: flex;
                gap: 15px;
            }

            .btn {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;
                font-size: 16px;
                font-weight: bold;
                padding: 12px 20px;
                border-radius: 50px;
                cursor: pointer;
                transition: 0.3s;
            }

            .primary {
                background-color: #3f2f84;
                color: white;
                border: none;

                &:hover {
                    background-color: #2d2060;
                }
            }

            .secondary {
                background: white;
                color: #3f2f84;
                border: 2px solid #3f2f84;

                &:hover {
                    background-color: #3f2f84;
                    color: white;
                }
            }
            .filter-modal-overlay {
                position: fixed;
                z-index: 2;
                top: 4%;
                left: 0;
                width: 100vw;
                height: 100vh;
                display: flex;
                align-items: center;
                justify-content: center;
                animation: fadeIn 0.3s ease-in-out; // Overlay fade-in animation
            }

            .filter-dropdown {
                background: white;
                padding: 20px;
                border-radius: 10px;
                transition: transform 0.3s ease, opacity 0.3s ease;
                transform: translateY(-10px);
                opacity: 0;

                &.open {
                    transform: translateY(0);
                    opacity: 1;
                }

                &.close {
                    transform: translateY(-20px);
                    opacity: 0;
                    transition: transform 0.2s ease, opacity 0.2s ease;
                }
            }

            /* Fade-in animation */
            @keyframes fadeIn {
                from {
                    opacity: 0;
                }
                to {
                    opacity: 1;
                }
            }

            .search-bar {
                display: flex;
                align-items: center;
                border: 2px solid #3f2f84;
                border-radius: 50px;
                padding: 10px 15px;
                // width: 300px;
                background: white;
                position: relative;
                gap: 0.5rem;

                input {
                    border: none;
                    outline: none;
                    flex-grow: 1;
                    text-align: right;
                    font-size: 16px;
                    color: #3f2f84;
                }

                .search-icon {
                    color: #3f2f84;
                    width: 24px;
                    height: 24px;
                    font-size: 18px;
                    cursor: pointer;
                }
            }
        }

        /* Filter Dropdown */
        .filter-dropdown {
            position: absolute;
            padding: 1rem;
            width: 33%;
            background: white;
            border-radius: 12px;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
            z-index: 150;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 16px;
            bottom: 5%;
            right: 20%;
            /* Left Side (Dates & Filters) */
            .left-column {
                display: flex;
                flex-direction: column;
                gap: 12px;

                .filter-section {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    text-align: right; /* Ensure RTL alignment */

                    label {
                        font-size: 14px;
                        font-weight: 600;
                        color: #3f2f84;
                    }

                    .date-range {
                        display: flex;
                        align-items: center;
                        gap: 8px;

                        input {
                            width: 140px;
                            padding: 8px;
                            border-radius: 8px;
                            border: 1px solid #ccc;
                            text-align: center;
                            min-height: 40px;
                        }
                    }

                    .filter-tags {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 6px;

                        .filter-tag {
                            background: #e4e0f1;
                            padding: 6px 10px;
                            border-radius: 16px;
                            font-size: 12px;
                            color: #3f2f84;
                            font-weight: 500;
                            cursor: pointer;

                            &::after {
                                content: '✖';
                                font-size: 14px;
                                margin-left: 6px;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }

            /* Right Side (Text & Dropdown Filters) */
            .right-column {
                display: flex;
                flex-direction: column;
                gap: 12px;

                .filter-options {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;

                    .option {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-size: 14px;
                        font-weight: 600;
                        color: #2d2060;
                        background: #f7f7fc;
                        padding: 10px 14px;
                        border-radius: 12px;
                        border: 1px solid #ccc;
                        cursor: pointer;
                        text-align: right;
                    }
                }
            }

            /* Full-Width Buttons at Bottom */
            /* Filter Buttons Container */
            .filter-buttons {
                position: absolute;
                bottom: 16px;
                left: 16px;
                display: flex;
                gap: 10px;

                .btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    font-weight: bold;
                    padding: 10px 20px;
                    border-radius: 24px;
                    cursor: pointer;
                    transition: 0.3s;
                    flex-grow: 1;
                    min-width: 120px; /* Ensures proper button width */
                }

                /* Default Secondary Button */
                .secondary {
                    background: white;
                    color: #3f2f84;
                    border: 2px solid #3f2f84;

                    &:hover {
                        background-color: #3f2f84;
                        color: white;
                    }
                }

                /* Active Primary Button */
                .primary {
                    background-color: #3f2f84;
                    color: white;
                    border: none;

                    &:hover {
                        background-color: #2d2060;
                    }
                }

                /* Inactive Primary Button (No Filters Applied) */
                .primary.disabled {
                    background-color: rgba(174, 165, 211, 1); /* Disabled color */
                    cursor: not-allowed;
                }
            }
        }
    }

    .events {
        display: flex;
        flex-direction: column;
        padding: 1em;
        gap: 24px;
        overflow: hidden;
    }

    .event-wheel-container {
        background-color: white;
        padding-top: 16px;
        width: 40%;
        overflow: hidden;
        border-radius: 16px;
        padding: 12px 24px 0 12px;

        .btn {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            font-size: 16px;
            font-weight: bold;
            padding: 12px 20px;
            border-radius: 50px;
            cursor: pointer;
            transition: 0.3s;
        }

        .primary {
            background-color: #3f2f84;
            color: white;
            border: none;

            &:hover {
                background-color: #2d2060;
            }
        }

        .secondary {
            background: white;
            color: #3f2f84;
            border: 2px solid #3f2f84;

            &:hover {
                background-color: #3f2f84;
                color: white;
            }
        }

        .eventsTitle {
            font-weight: 600;
            font-size: 32px;
            line-height: 38.4px;
        }
    }

    .events-wheel {
        overflow-y: scroll;
        height: 100%;
        width: 100%;
        direction: ltr;

        &::-webkit-scrollbar {
            width: 20px;
        }

        &::-webkit-scrollbar-track {
            background: #e9e9f0;
            border: 6px solid white;
        }

        &::-webkit-scrollbar-thumb {
            background: rgba(17, 8, 53, 1);
            border-radius: 40px;
        }
    }
}
.input-container {
    display: flex;
    align-items: center;
    background: white;
    border: 1px solid #d1d1d6; /* Light gray border */
    border-radius: 50px; /* Rounded corners */
    padding: 0.2em;
    width: 100%;
    position: relative;

    input {
        border: none;
        outline: none;
        flex-grow: 1;
        font-size: 16px;
        color: #3f2f84;
        background: transparent;
        text-align: right; /* RTL alignment */
        padding: 5px 0;
    }

    .input-icon {
        width: 20px;
        height: 20px;
        color: #8e8e93; /* Light gray icon */
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    /* Placeholder Styling */
    input::placeholder {
        color: #8e8e93;
        font-size: 14px;
    }
}
