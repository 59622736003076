.form-switch {
    position: relative;
    padding-left: 0;
    margin-left: 0 !important;

    .form-check-input {
        width: 42px;
        height: 24px;

        &:checked {
            box-shadow: none !important;
            background-color: var(--green);
            padding: 0 10px;
        }
    }

    .form-check-input {
        border: none;
        cursor: pointer;

        &:not(:checked) {
            background-color: var(--red);
            color: var(--white);
            //background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e');
            background-image: url('./x.svg');
        }

        &:checked {
            background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e');
        }

        &:active {
            filter: none;
        }

        &:not(:disabled) {
            &:checked,
            &:focus {
                box-shadow: none !important;
            }
        }
    }
}

.input-number {
    padding: 0.375rem 0.75rem;
}
