.purchase-summary {
  width: 313px;
  padding: 20px;
  border-radius: 6px;
  background-color: var(--white);
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.06);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  .summary {
    display: flex;
    flex-direction: column;

    .summary-amount {
      font-size: 24px;
      font-weight: 600;
      color: var(--title-grey-color);
    }

    .summary-label {
      font-size: 14px;
      line-height: 1.5;
      color: var(--title-grey-color);
    }

    .summary-icon {
      height: 46px;
      width: 46px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }


}