.modal-wrapper {
    display: flex;
    padding: 64px;
    flex-direction: column;
    box-sizing: border-box;

    .modal-footer-align-left {
        display: flex;
        justify-content: left;
        gap: 12px;
    }

    .modal-footer-align-right {
        display: flex;
        justify-content: right;
        gap: 12px;
    }

    .modal-footer-align-center {
        justify-content: center;
    }
}

.dynamic-modal-header {
    font-family: Rubik-Medium;
    font-weight: 500;
    line-height: 1.25;
    color: var(--black);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.dynamic-modal-content {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    box-sizing: border-box;
    white-space: pre-line;
    align-items: center;
    max-height: 500px;
    overflow: auto;
    padding: 16px;
}

.close-icon {
    position: absolute;
    display: flex;
    top: -5px;
    left: -5px;
    width: 34px;
    height: 34px;
    box-shadow: 0 4px 15px 0 rgba(44, 63, 88, 0.35);
    border-radius: 6px;
    background: var(--white);
    border: none;
}

.modal-with-icon {
    gap: 7px;

    .dynamic-modal-header {
        font-size: 21px;
    }
}

.modal-without-icon {
    gap: 27px;

    .dynamic-modal-header {
        font-size: 28px;
    }
}

.dynamic-modal-footer {
    display: flex;
    gap: 16px;

    .footer-button {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0.4px;
        padding: 12px 24px;
    }

    .modal-button-icon-left {
        display: flex;
        gap: 10px;
    }

    .modal-button-icon-right {
        display: flex;
        flex-direction: row-reverse;
        gap: 10px;
    }
}

.choose-modal {
    display: flex;
    height: 150px;
    width: 300px;
}

.report-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    padding: 0 24px;
    font-size: 14px;
    line-height: 1.5;
    color: var(--title-grey-color);
    max-height: 500px;

    .report-title {
        display: flex;
        font-size: 18px;
        font-weight: 500;
        text-align: right;
        color: var(--title-grey-color);
    }
}

.report-table {
    padding: 24px;
    overflow: auto;
    height: 50%;
    width: 100%;

    th,
    tr,
    td,
    body {
        border: solid 1px var(--black);
        padding: 4px 24px;
    }
}
