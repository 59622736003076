.image-section {
    display: flex;
    width: 100%;
    gap: 24px;

    .img-profile {
        height: 85px;
        object-fit: contain;
        max-width: 240px;
    }

    .square-image {
        border-radius: 0 !important;
    }

    .image-upload {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
        justify-content: center;

        .upload-buttons {
            display: flex;
            align-items: center;
            gap: 16px;
        }
    }
}
