@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

.richtext-label {
    font-size: 12px;
}

.rdw-editor-wrapper {
    display: flex;
    flex-direction: column;
    box-sizing: content-box;
    margin: 0;

    .richtext-disabled {
        background-color: var(--bs-secondary-bg) !important;
        cursor: auto !important;
    }

    .rdw-editor-toolbar {
        direction: rtl;
        background-color: var(--white);
        margin-bottom: 0;
        padding: 0.5rem 0.5rem 0;
        border-color: var(--border);
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;

        .rdw-option-wrapper {
            border: none;
            padding: 13px 5px;
            &.rdw-option-active {
                background-color: rgba($color: var(--bs-border-width), $alpha: 0.2);
            }
            &:hover {
                box-shadow: none;
            }
        }

        .rdw-fontfamily-dropdown,
        .rdw-block-dropdown,
        .rdw-colorpicker-wrapper,
        .rdw-fontsize-wrapper,
        .rdw-link-wrapper,
        .rdw-embedded-wrapper,
        .rdw-emoji-wrapper,
        .rdw-image-wrapper,
        .rdw-remove-wrapper,
        .rdw-text-align-wrapper,
        .rdw-history-wrapper {
            display: none !important;
        }

        .rdw-list-wrapper {
            .rdw-option-disabled {
                display: none !important;
            }
        }

        .rdw-inline-wrapper div:nth-child(n + 3):nth-child(n + 4) {
            display: none;
        }

        .rdw-dropdown-wrapper {
            direction: initial;
            border: 1px solid var(--secondary);

            .rdw-dropdown-optionwrapper {
                &:hover {
                    box-shadow: none;
                }
            }
            &:hover {
                box-shadow: none;
                .rdw-dropdown-selectedtext {
                    color: var(--primary);
                }
            }
            .rdw-dropdownoption-default {
                &.rdw-dropdownoption-highlighted {
                    color: var(--primary);
                    background: transparent;
                }
            }
        }
        .rdw-link-modal,
        .rdw-embedded-modal {
            height: auto;
        }
    }

    .rdw-editor-main {
        background-color: var(--white);
        min-height: 10rem;
        max-height: 300px;
        box-sizing: border-box !important;
        cursor: text;
        padding: 0.5rem 1.2rem;
        border: 1px solid var(--border);
        border-bottom-left-radius: var(--bs-border-width);
        border-bottom-right-radius: var(--bs-border-width);
        text-align: right;
    }

    .rdw-editor-toolbar ~ .rdw-editor-main {
        border-top: none;
    }

    .rdw-editor-main {
        .DraftEditor-root {
            .DraftEditor-editorContainer {
                .public-DraftEditor-content {
                    .public-DraftStyleDefault-block {
                        text-align: right !important;
                    }
                }
            }
        }
    }

    &.toolbar-bottom {
        display: flex;
        flex-direction: column;
        .rdw-editor-toolbar {
            order: 2;
            border-radius: 0 0 99px 99px;
        }
        .rdw-editor-main {
            border-top: 1px solid var(--bs-border-width);
            border-bottom: 0;
            border-radius: 99px 99px 0 0;
            order: 1;
        }
    }
}
