.user-icon {
  box-sizing: border-box;
  padding: 10px;
  border-radius: 50%;
  background-color: var(--primary);
  color: var(--white);
  font-size: 14px;
  font-weight: 600;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

.user-image {
  border-radius: 50%;
}