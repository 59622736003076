.delete-field {
    width: 40px;
    height: 38px;
    padding: 0 !important;
    border-radius: 50%;
    position: absolute;
    left: -54px;
    bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}
