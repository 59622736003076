.custom-timeline-content {
    margin: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 6px 16px;
    text-align: left;
}