$product-bg: #f5efff;
$product-card-bg: #ffffff;
$shadow-color: rgba(0, 0, 0, 0.1);

.product-list {
    background: #ffffff;
    padding: 16px;
    border-radius: 20px;
    max-width: 280px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: grid;
    min-height: fit-content;

    .header-ProductCard {
        display: flex;
        justify-content: space-between;
        font-size: 20.26px;
        font-weight: 600;
        color: rgba(35, 25, 77, 1);

        .shopping-bag-icon {
            font-size: 22px;
        }

        .title {
            font-weight: 600;
            font-size: 20.26px;
            text-align: right;
            .underline {
                text-decoration: underline;
            }
        }
    }
}

.product-card {
    display: flex;
    background: $product-card-bg;
    border-radius: 16px;
    padding: 5px;
    box-shadow: 0 4px 8px $shadow-color;
    align-items: center;
    gap: 12px;
    margin-bottom: 16px;
    direction: rtl; // Ensure RTL support for Hebrew

    .product-image {
        width: 80px;
        height: 80px;
        border-radius: 12px;
        object-fit: cover;
    }

    .product-info {
        flex: 1;
        display: flex;
        flex-direction: column;
        text-align: right;

        .product-title {
            font-size: 12.16px;
            font-weight: 400;
            line-height: 9.73px;
            text-align: right;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;

            margin-bottom: 6px;
        }

        .product-stats {
            display: flex;
            justify-content: flex-start;
            gap: 12px;
            font-size: 12px;
            color: gray;

            .likes {
                font-weight: bold;
                color: black;
            }
        }
    }
}
