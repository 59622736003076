.chip {
    display: flex;
    align-items: center;
    border-radius: 17px;
    height: 20px;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.5;
    font-family: Rubik-SemiBold;
    padding: 1px 9px;
}

.success {
    background-color: var(--color-success-background) !important;
    //color: var(--color-success);
    color: var(--white);
}

.danger {
    //background-color: var(--color-error-background) !important;
    //color: var(--color-error);
}
