.select__multi-value {
    color: var(--white);
    background-color: var(--primary) !important;
    border-radius: 99px !important;

    .select__multi-value__label {
        color: var(--white);
    }

    .select__placeholder {
        margin-right: 5px;
    }
}

.container-field {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.select__control {
    border-radius: 99px !important;

    .select__value-container {
        .select__placeholder {
            margin-right: 12px;
        }

        .select__indicators {
            .select__indicator-separator {
                z-index: 99 !important;
            }
        }
    }
}

.react-select {
    max-height: 38px !important;
    border-radius: 99px !important;
    width: 100%;
}

.react-select__single-value {
    height: 24px;
    padding: 0;
    color: #333;
    border-radius: 99px !important;
}
