.who-replied {
    background: rgba(246, 245, 250, 1);
    border-radius: 12px;
    padding: 16px;
    width: calc(95% / 3);

    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    position: relative;
    display: flex;
    flex-direction: column;
}

.header-WhoReplied {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

.replies-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    max-height: 300px;
    padding-right: 5px;
}

/* Single Reply Item */
.reply-item {
    display: flex;
    align-items: center;
    background: #f8f8f8;
    border-radius: 10px;
    padding: 10px;
    border: 1px solid #eee;
    gap: 10px;
}

/* Profile Image */
.profile-img {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    object-fit: cover;
}

/* Reply Text and User Info */
.reply-info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

/* Name and Role */
.user-details {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #333;
    font-weight: bold;
}

.user-name {
    font-weight: bold;
    color: #000;
}

.user-role {
    font-size: 14px;
    color: gray;
    font-weight: 500;
}

.separator {
    margin: 0 5px;
    font-weight: bold;
    color: #333;
}

/* Reply Text */
.reply-text {
    font-size: 15px;
    color: black;
    margin-top: 5px;
}

/* Timestamp */
.timestamp {
    font-size: 12px;
    color: gray;
    margin: 0 4px 0px auto;
}

/* No replies message */
.no-replies {
    text-align: center;
    color: #999;
}
