$card-bg: #ffffff;
$shadow-color: rgba(0, 0, 0, 0.1);
$green: #24b47e;
$text-color: #1d1b4d;

.active-users-card {
    background: $card-bg;
    padding: 10px;
    border-radius: 20px;
    box-shadow: 0 5px 10px $shadow-color;
    text-align: center;
    direction: rtl;
    width: 20%;

    .header-ActiveUsersCard {
        display: flex;
        justify-content: space-between;

        .icon {
            font-size: 22px;
            opacity: 0.3;
        }

        .title {
            h2 {
                font-weight: 600;
                font-size: 20.26px;
                text-align: right;
            }

            .subtitle {
                font-size: 14px;
                color: gray;
                margin-top: 4px;
            }
        }
    }

    .user-stats {
        margin-top: 10px;

        .user-count {
            font-size: clamp(35px, 4vw, 60px);
            font-weight: bold;
            color: $text-color;
            margin: 0;
        }

        .growth {
            font-size: 14px;
            font-weight: bold;
            color: $green;
            margin: 0;
        }
    }
}
