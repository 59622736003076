$bg-color: rgba(244, 244, 244, 1);
$card-bg: #ffffff;
$shadow-color: rgba(0, 0, 0, 0.1);
$green: #24b47e;
$red: #ff6b6b;
$blue: #1d1b4d;

.budget-summary {
    background: $card-bg;
    padding: 16px;
    border-radius: 20px;
    max-width: 280px;
    box-shadow: 0 5px 10px $shadow-color;
    text-align: center;
    display: grid;
    height: fit-content;
    .header-BudgetSummary {
        display: flex;
        font-size: 20px;
        font-weight: bold;
        justify-content: space-between;

        .icon {
            font-size: 22px;
        }

        .title {
            font-weight: 600;
            font-size: 20.26px;
            text-align: right;
            .underline {
                text-decoration: underline;
            }
        }
    }

    .section {
        flex-grow: 1;
        min-height: 50px;

        .amount {
            font-size: clamp(20px, 2vw, 32px);
            font-weight: bold;
        }

        .green {
            color: $green;
        }

        .red {
            color: $red;
        }

        .blue {
            color: $blue;
        }

        .label {
            font-size: 14px;
            color: #333;
        }
    }

    hr {
        border: none;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        width: 80%;
        margin: 10px auto;
    }
}
