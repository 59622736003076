.line {
    width: 1px;
    height: 26px;
    flex-grow: 0;
    margin: 0 13px;
    border: solid 1px var(--color-separator);
}

.content-header {
    display: flex;
    align-items: center;
}

.content-header-title {
    font-size: 24px;
    font-weight: 600;
    color: var(--color-title);
    margin-bottom: 0;
}

.breadcrumb-item {
    font-size: 12px;
    color: var(--title-grey-color);
    padding-right: 0 !important;
    padding-left: 12px;
    display: flex;
    align-items: center;
}

.text-primary {
    font-size: 14px;
    color: var(--title-grey-color) !important;
}

.breadcrumb {
    margin-bottom: 0;
}

.breadcrumb-chevron {
    &.breadcrumb {
        display: flex;
        align-items: center;
        .breadcrumb-item + .breadcrumb-item:before {
            padding-left: 8px;
            padding-right: 0;
            content: '>';
        }
    }
}
