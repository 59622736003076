.empty-state-wrapper {
    display: flex;
    width: 100%;
    padding: 40px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    gap: 25px;

    .empty-state-wrapper-title {
        font-size: 21px;
        font-weight: 500;
    }

    .empty-state-wrapper-button {
        margin-top: 16px;
        font-size: 14px;
    }
}
