.reports {
    margin: 30px 0 0;
    display: flex;
    flex-direction: column;
    gap: 28px;
    width: 100%;
    box-sizing: border-box;

    .management {
        display: flex;
        flex-direction: column;
        gap: 28px;
        width: 100%;
        box-sizing: border-box;
    }
}
