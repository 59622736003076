.button-general {
    border-radius: 99px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.4px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    .mini {
        padding: 4px 19px;
    }

    .button-and-icon {
        display: flex;
        align-items: center;
        padding: 6.5px 23px;
        box-sizing: border-box;
        gap: 10px;
    }

    .modal-button-icon-left {
        display: flex;
        flex-direction: row-reverse;
    }
}

.btn-group {
    .dropdown-label {
        border: none;
        padding: 0;
        background-color: inherit;
        color: black;

        &:hover {
            background-color: var(--color-square);
        }
    }
}

.button-general.flex-row-reverse .button-and-icon {
    display: flex;
    flex-direction: row-reverse;
}

.btn-primary {
    background-color: var(--primary);
    border-color: var(--primary);
}

.danger {
    background-color: red !important;
    color: var(--white) !important;

    &:hover {
        opacity: 0.5;
    }
}

.btn-outline-primary {
    border-color: var(--primary);
    color: var(--primary);
}

.btn-secondary {
    background-color: var(--white);
    color: var(--primary);
    border: 1px solid var(--primary);
}

.btn-grey {
    background-color: var(--white);
    color: var(--secondary);
    border: 1px solid var(--secondary);
}

.mini {
    padding: 4px 19px;
}

.grey {
    background-color: transparent;
    border: solid 1px #949396;
}

.dropdown-item {
    padding: 9px 16px;
}
