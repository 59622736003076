.recharts-default-legend {
    max-height: 150px;
    overflow-y: auto;
}

.recharts-legend-item span {
    display: inline-block;
    max-width: 220px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.legend-item-text {
    display: 'block';
    max-width: '250px';
    overflow: 'hidden';
    white-space: 'nowrap';
    text-overflow: 'ellipsis';
    margin-right: '5px';
}

.custom-tooltip {
    background-color: #fff; /* Dark background */
    color: black; /* White text */
    padding: 1rem;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    font-size: 1rem;
    text-align: left;
}

.custom-tooltip .total-label {
    font-weight: bold;
    margin-bottom: 5px;
}

.custom-tooltip .segment-label {
    margin: 0;
    font-size: 1rem;
}

.zero-visit-div {
    display: grid;
    padding: 2.5rem;
    justify-items: center;
}
.zero-visit-wrapper {
    display: grid;
    padding: 1rem;
}

.zero-visit-title {
    margin-bottom: 1.5rem;
}
.zero-visit-close {
    position: fixed;
    left: 2%;
    top: 2%;
}
