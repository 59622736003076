$boost-bg: #f5efff;
$card-bg: #ffffff;
$shadow-color: rgba(0, 0, 0, 0.1);
$primary-color: #3d2d8b;
$secondary-color: #d6cff1;
$green: #24b47e;

.boost-statistics {
    background: $card-bg;
    padding: 10px;
    box-shadow: 0 5px 10px $shadow-color;
    text-align: center;
    border-radius: 20px;
    width: 20%;

    .header-BoostStatistics {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 20px;
        font-weight: bold;

        .icon {
            font-size: 22px;
            opacity: 0.6;
        }

        .title {
            font-weight: 600;
            font-size: 20.26px;
            text-align: right;

            .underline {
                height: 2px;
                width: 50%;
                background: $primary-color;
                margin-top: 2px;
            }
        }
    }

    .chart-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .chart-center {
            position: absolute;
            text-align: center;

            .big-text {
                font-size: 28.37px;
                font-weight: 700;
                margin: 0;
            }

            .sub-text {
                font-weight: 700;
                font-size: 13px;
                line-height: 12.16px;
                text-align: center;
            }

            .increase {
                font-size: 14px;
                font-weight: bold;
                color: $green;
                margin: 0;
            }
        }
    }
}
.custom-tooltip-Statistics {
    display: flex;
    z-index: 32;
    background: rgba(35, 25, 77, 1);
    color: white;
    border-radius: 10px;
    padding: 10px;
    z-index: 999;
}
