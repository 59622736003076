.users {
    margin: 30px 0 0;
    display: flex;
    flex-direction: column;
    gap: 28px;
    width: 100%;
    box-sizing: border-box;

    .management {
        display: flex;
        flex-direction: column;
        gap: 28px;
        width: 100%;
        box-sizing: border-box;
    }

    .steps-tabs {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;
    }
}

.table-text {
    display: flex;
    gap: 20px;

    .table-icon {
        cursor: pointer;
    }
}

.step-6 {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 2px;

    .step-title {
        color: var(--color-title);
        font-size: 24px;
        font-weight: 600;
    }

    .inputs {
        display: flex;
        flex-direction: column;
        gap: 32px;

        .purchases {
            display: flex;
            gap: 16px;
        }
    }

    .status-label {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        background-color: rgba(38, 188, 126, 0.12);
        color: var(--color-success);
        border-radius: 17px;
        padding: 1px 9px;
        font-size: 12px;
        font-weight: 600;
        line-height: 1.5;
    }
}

.users-child {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 0 16px;
}
