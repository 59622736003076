.page {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    overflow: auto;
}

.App {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;

    .content {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 12px 28px 10px;
        height: 100%;
        width: 100%;
        overflow: auto;
    }
}
