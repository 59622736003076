.Toastify__progress-bar--animated.Toastify__progress-bar--success {
    background-color: var(--primary);
}
.Toastify__toast-body .Toastify__toast-success .Toastify__icon {
    background-color: var(--primary);
}

.step {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 16px;
    width: 644px;
    box-sizing: border-box;
    padding: 0 2px;

    .step-1-footer {
        display: flex;
        flex-direction: column;
        gap: 48px;

        .save-and-cancel {
            display: flex;
            align-items: center;
            gap: 16px;
        }
    }

    .inputs-separator {
        border-bottom: 1px solid var(--input-icon);
        height: 1px;
        width: 100%;
    }

    .step-row {
        display: grid;
        grid-column: 1 / span 2; /* Span from the first column to the second column */
    }

    .full-row {
        grid-column: 1 / span 2; /* Span from the first column to the second column */
    }

    .field-with-publicity {
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 100%;
    }

    .unique-field-with-publicity {
        display: grid;
    }

    .step-title {
        display: contents;
        grid-column: 1 / span 2; /* Span from the first column to the second column */
        color: var(--color-title);
        font-size: 24px;
        font-weight: 600;
    }

    .filler {
        display: flex;
        flex: 1;
    }

    .checkbox-with-label {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        gap: 10px;
        padding-bottom: 6px;

        .checkbox-label {
            height: 100%;
            display: flex;
            flex-direction: column;
            flex: 1;
            justify-content: center;
            align-items: flex-start;
            font-size: 14px;
            font-weight: 600;
            line-height: 1.5;
            color: var(--title-grey-color);

            .checkbox-subtext {
                font-size: 12px;
                color: var(--color-placeholder-text);
            }
        }
    }
}

.button-center {
    display: flex;
    align-items: center;
    gap: 12px;
}
