.pagination-container {
    padding: 0;
    margin: 0;
    border: none;
    direction: ltr;

    li,
    li.disabled {
        border-radius: 0;
        background: var(--table-header);

        a {
            border: none;
            border-radius: 50%;
            width: 28px;
            height: 28px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            color: var(--title-grey-color);
            background: none;
        }
    }

    li.disabled a {
        color: var(--input-icon);
    }

    li.active a {
        color: var(--white);
        position: relative;
    }

    li.active a:before {
        content: '';
        background: var(--primary);
        color: var(--white);
        width: 32px;
        height: 32px;
        position: absolute;
        border-radius: 50%;
        z-index: -1;
    }

    .page-item .page-link {
        z-index: 0;
        border-radius: 50%;
    }

    .page-item.prev {
        margin-right: 4px;
        border-radius: 50%;
    }

    .page-item.next {
        margin-left: 4px;
        border-radius: 50%;
    }

    .page-item:nth-child(2) {
        border-radius: 50% 0 0 50%;
    }

    .page-item:nth-last-child(2) {
        border-radius: 0 50% 50% 0;
    }
}
