.widgets {
    display: flex;
    gap: 25px;
    justify-content: flex-end;
    height: 50%;
    padding: 20px 0;
    overflow: auto;

    .long-widgets {
        display: flex;
        gap: 25px;
    }
    .flex-widgets-wrapper {
        display: grid;
        gap: 10px;
        width: 70%;
        max-width: 100%;

        .flex-widgets {
            display: flex;
            gap: 25px;
            flex-wrap: nowrap;
        }
    }
}
