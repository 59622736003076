@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'scss/_fonts.scss';
@import 'scss/shared';

:root {
    --title-grey-color: #414045;
    --table-header: #f3f2f7;
    --color-text-main: #444b4d;
    --color-text-second: rgba(68, 75, 77, 0.3);
    --color-logout: #e14069;
    --color-logout-light: rgba(225, 64, 105, 0.24);
    --grey-light: #f8f8f8;
    --color-border-digit: #d8d6de;
    --color-square: #d8d8d8;
    --color-icon: #4b4b4b;
    --color-icon-light: rgba(29.41, 29.41, 29.41, 0.05);
    --primary: #4833a0;
    --checkbox-primary: #7367f0;
    --secondary: #949396;
    --white: #ffffff;
    --black: #000000;
    --red: #ff0000;
    --green: #26bc7e;
    --theme-dark-card-bg: 000000;
    --primary-light: rgba(2, 130, 255, 0.24);
    --primary-light-v2: #f6f5fa;
    --color-placeholder-text: #b9b9c3;
    --color-error: #ea5455;
    --color-error-background: rgba(255, 63, 47, 0.12);
    --color-title: #5e5873;
    --user-title: #6e6b7b;
    --input-icon: #d9d9da;
    --border: #e9ecef;
    --color-separator: #ebe9f1;
    --color-success: #26bc7e;
    --color-success-background: rgba(38, 188, 126);
    --color-warning: orange;
    --color-chosen: #68e0f0;
}

html {
    direction: rtl;

    height: 100%;
    margin: 0;
}

body {
    height: 100vh;
    margin: 0;
    font-family: Rubik-Regular;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image: url('./assets/Frame\ 1432.svg'); /* Import the SVG */
    background-repeat: no-repeat;
    background-position: top;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
    height: 100%;
    width: 100%;
}
