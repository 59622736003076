@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace +
            str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

$chevron-left: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-left'%3E%3Cpolyline points='15 18 9 12 15 6'%3E%3C/polyline%3E%3C/svg%3E";
$chevron-right: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E";

.table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    width: 100%;

    .components {
        display: flex;
        gap: 16px;
        align-items: center;
    }

    .rows-per-page {
        display: flex;
        align-items: center;
        font-size: 14px;
        gap: 12px;
    }
}

.select__single-value {
    font-size: 12px;
}

.select__placeholder {
    font-size: 12px;
}

.data-filter {
    border-radius: 6px;
    border: none;
    box-shadow: 0 4px 24px 0 rgba(24, 41, 47, 0.1);
    box-sizing: border-box;

    .name-input {
        font-size: 12px;
    }

    .select__value-container {
        padding: 3px;
        font-size: 12px;
        box-sizing: border-box;

        .select__single-value {
            color: var(--white);
        }
    }

    .react-select-disabled {
        .select__single-value {
            color: var(--white);
        }
    }

    .search-icon {
        position: absolute;
        right: 10px;
        bottom: 10px;
    }
}

.edit-user-btn,
.delete-user-btn,
.duplicate-user-btn {
    cursor: pointer;
}

a {
    color: unset;
}

.data-table {
    box-shadow: 0 4px 24px 0 rgba(24, 41, 47, 0.1);
    border: none !important;

    header {
        min-height: auto;
    }

    .react-dataTable [class^='sc'] {
        display: flex !important;
    }

    .pointer {
        cursor: pointer;
    }

    .react-dataTable {
        height: 100%;
        overflow: auto;
        border-radius: 0;

        .react-data-table-component-checkbox input[type='checkbox'] {
            /* Apply your custom checkbox styles */
            /* For example, you can change the checkbox size, color, or add custom icons */
            /* Here's an example of changing the checkbox color to red */
            border-color: red;
            background-color: red;
        }

        .react-data-table-component-checkbox span {
            /* Apply additional custom styles to the checkbox wrapper if needed */
            /* For example, you can modify the checkbox's alignment or spacing */
        }

        .rdt_TableHeadRow {
            font-weight: 600;
            border-radius: 0;
            background-color: var(--table-header) !important;
        }

        .rdt_TableRow {
            border: none;

            border-bottom: 1px solid var(--border);
        }

        .rdt_Pagination {
            direction: ltr;
            display: flex;
            justify-content: flex-start;
        }

        .rdt_TableCell {
            width: 100%;
            font-size: 14px;
            font-weight: 600;
            display: flex;
        }

        .rdt_TableCell:last-child {
            justify-content: flex-end;
        }
    }
}

.table-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 180px);

    .data-table {
        flex: 1;
        min-height: 0;
    }
}

.dataTable-content {
    display: flex;
    flex-grow: 0;
    flex-direction: column;
    overflow: auto;

    header {
        flex-grow: 0;
    }

    & > div:nth-child(2) {
        flex-grow: 1;
        overflow-y: auto;
    }

    .table-text {
        font-size: 14px;
        line-height: 1.5;
        color: var(--title-grey-color);
        font-weight: bold;

        .table-icon {
            cursor: pointer;
        }
    }

    *[role='columnheader'] {
        font-size: 12px;
        color: var(--title-grey-color);
        font-weight: 600;
        letter-spacing: 1px;
    }
}

// Pagination

// default look for page item, first and last item

.page-item {
    border-radius: 29px;

    a {
        &:is(.prev-item),
        &:is(.next-item) {
            border-radius: 29px;
        }

        &:not(.first),
        &:not(.last),
        &:not(.active) {
            .page-link {
                line-height: 1.3;
            }
        }
    }
}
