.color-picker {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  box-sizing: border-box;
  width: 100%;

  .color-picked {
    height: 38px;
    width: 38px;
    border-radius: 50%;
    flex-shrink: 0;
    border: solid 1px var(--color-border-digit);
  }
}