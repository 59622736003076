$card-bg: #ffffff;
$shadow-color: rgba(0, 0, 0, 0.1);
$primary-color: #3d2d8b;
$secondary-color: #d6cff1;

.budget-history {
    background: $card-bg;
    padding: 10px;
    border-radius: 16px;
    width: 100%;
    max-width: 600px;
    margin: 0 auto; // Center it
    box-shadow: 0 4px 8px $shadow-color;
    padding: clamp(8px, 2vw, 16px);
    text-align: center;
    direction: rtl;
    .header-BudgetHistory {
        display: flex;
        flex-wrap: wrap; // Allows wrapping when needed
        align-items: center;
        justify-content: space-between;
        gap: 8px; // Adds spacing on smaller screens
        .title {
            font-weight: 600;
            font-size: clamp(16px, 2.5vw, 20px);
            text-align: right;
        }

        .icon {
            font-size: 20px;
            opacity: 0.7;
        }
    }

    // .chart-container {
    //     background: $card-bg;
    //     border-radius: 12px;
    //     width: 100%;
    //     height: 100%;
    //     // min-height: clamp(100px, 25vh, 200px); // Responsive height
    // }
}

.custom-tooltip {
    background: $primary-color;
    color: white;
    padding: 10px;
    border-radius: 8px;
    font-weight: bold;
    text-align: center;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
        border-width: 8px;
        border-style: solid;
        border-color: $primary-color transparent transparent transparent;
    }
}
