.product-desc {
    display: flex;
    gap: 12px;
    align-items: center;
    height: 32px;

    .title-and-catalog-number {
        display: flex;
        flex-direction: column;

        .title {
            font-weight: 600;
        }

        .subtitle {
            color: var(--color-placeholder-text);
        }
    }
}
