.radio-buttons {
    &.display-flex {
        display: flex;
    }

    &.display-grid {
        display: grid;
        gap: 16px;
    }
    gap: 16px;
}

.button-label-and-radio {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
}

.form-check-input {
    height: 18px;
    width: 18px;
    margin: 0;
}

.form-check-input:checked[type='radio'] {
    background-color: var(--checkbox-primary);
    box-shadow: 0 2px 4px 0 rgba(115, 103, 240, 0.4);
    border: none;
    background-image: none;
}
