.posts-chart {
    background: #ffffff;
    border-radius: 12px;
    padding: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    width: 100%; // Full width for flexibility
    max-width: 600px; // Prevent it from becoming too wide
    margin: 0 auto; // Center it on larger screens
}

.header-PostsChart {
    display: flex;
    flex-wrap: wrap; // Allows wrapping on small screens
    align-items: center;
    gap: 10px;
    justify-content: space-between;
}

.icon {
    font-size: 20px;
    color: #3d2d8b;
}

.title-container {
    display: flex;
    flex-direction: column;
}

.title {
    font-weight: 600;
    font-size: clamp(16px, 2vw, 20px);
    text-align: right;
    margin: 0;
}

.subtitle {
    font-size: clamp(12px, 1.5vw, 14px);
    color: #888;
    margin: 0;
}

// .chart-container {
//     width: 100%;
//     height: auto;
//     min-height: 100px;
//     max-height: 200px;
// }

.custom-tooltip {
    background: white;
    padding: 6px 12px;
    border-radius: 6px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
}

.tooltip-value {
    font-weight: bold;
    color: #3d2d8b;
}
