.companies {
    margin: 30px 0 0;
    display: flex;
    flex-direction: column;
    gap: 28px;
    width: 100%;
    box-sizing: border-box;

    .management {
        display: flex;
        flex-direction: column;
        gap: 28px;
        width: 100%;
        box-sizing: border-box;
    }

    .steps-tabs {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;
    }
}

.hashtag-modal {
    display: flex;
    flex-direction: column;
    align-items: center;

    .modal-buttons {
        align-items: flex-end;
    }
}
