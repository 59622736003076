// .column-content {
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
//     justify-content: flex-start;
//     overflow: hidden;
//     word-break: break-word;
//     padding: 10px;

//     @media (max-width: 600px) {
//         align-items: center;
//         justify-content: center;
//         text-align: center;
//     }
// }

// .timeline-container {
//     padding: 15px;
// }
.column-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    word-break: break-word;
    padding: 10px;

    @media (max-width: 600px) {
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}

.timeline-container {
    padding: 15px;
}

.status-header {
    //grid-column: 1 / span 2; /* Span from the first column to the second column */
    color: var(--color-title);
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
}

.status-body {
    font-family: "Rubik-Regular";
    font-size: 12px;
    margin-bottom: 5px;
}
