.post-container {
    border: 2px solid rgba(255, 255, 255, 1);
    border-radius: 10px;
    box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.15);
    width: 98%;

    .post-wrapper {
        direction: rtl;
        background-color: var(--primary-light-v2);
        border-radius: 10px;
    }

    .post-header {
        display: flex;
        justify-content: space-between;
        padding: 0.5em;
        min-height: 200px;
        gap: 5px;
        .publisher-details {
            display: flex;
            gap: 13px;
            flex-direction: column;

            .user-avatar {
                display: flex;
                gap: 1em;
            }

            .publisher-image {
                width: 42px;
                height: 42px;
                object-fit: cover;
                border-radius: 50%;
            }

            .publisher-and-role {
                display: flex;
                flex-direction: column;

                .publisher {
                    font-size: 20px;
                    font-weight: 700;
                    text-align: right;
                }

                .user-role {
                    color: var(--color-placeholder-text);
                    font-size: 16px;
                    font-weight: 400;
                    text-align: right;
                }
            }
        }

        .published-at {
            font-size: 16px;
            font-weight: 600;
            text-align: right;
            color: var(--title-grey-color);
            position: absolute;
            left: 12px;
            width: max-content;
            z-index: 1;
        }
    }

    .post-content {
        display: flex;
        flex-direction: column;
        gap: 18px;

        .post-content-wrapper {
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 30%;
            }
            display: flex;
            width: 100%;
            gap: 15px;
            justify-content: space-between;
            position: relative;
            .post-image-cover {
                width: 350px;
                height: 183.72px;
                object-fit: scale-down;
                border-radius: 10px;
                position: absolute;
            }

            .post-image {
                width: 350px;
                height: 183.72px;
                object-fit: fill;
                border-radius: 10px;
                filter: blur(2px);
                position: absolute;
                left: 0;
                top: 0;
            }

            .text-content {
                flex: 1;
                font-size: 15px;
                font-weight: 600;
                line-height: 1.6;
                color: var(--title-grey-color);
                overflow-wrap: break-word;
            }
        }

        .post-details-and-settings {
            display: flex;
            border-top: 1px solid rgb(236, 236, 239);
            gap: 16px;
            justify-content: space-between;
            padding: 1%;

            .post-buttons {
                display: flex;
                gap: 1em;
            }

            .post-detail {
                display: flex;
                gap: 5px;
                align-items: center;
            }

            .post-detail-big-gap {
                display: flex;
                margin-left: 70px;
                align-items: center;
            }

            .detail-cursor {
                cursor: pointer;
            }
        }
    }
}

/* ========== MODAL STYLING ========== */
.post-modal-overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.post-modal {
    background: white;
    border-radius: 12px;
    width: 80%;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 80vh;
    overflow-y: auto;
    gap: 20px;
}

.close-button {
    position: absolute;
    top: 0px;
    right: 0px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
}

.modal-header {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

.replies-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 300px;
    overflow-y: auto;
}

.reply-item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border-bottom: 1px solid #eee;
}

.profile-img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
}

.no-replies {
    text-align: center;
    color: #999;
}

.bottom-post {
    display: flex;
    gap: 22px;
}
