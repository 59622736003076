.published-in-groups {
    background: rgba(246, 245, 250, 1);
    border-radius: 12px;
    padding: 16px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    position: relative;
    display: flex;
    flex-direction: column;
    width: calc(95% / 3);
}

.header-PublishedInGroups {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

.groups-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    max-height: 300px;
    padding-right: 5px;
}

/* Single Group Row */
.group-item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border-bottom: 1px solid #eee;
}

/* Alternate background for odd/even rows */
.group-item:nth-child(odd) {
    background: rgba(228, 224, 241, 1);
}

.group-item:nth-child(even) {
    background: inherit;
}

/* Group Image */
.group-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

/* Group Name */
.group-name {
    font-size: 14px;
    color: black;
    font-weight: 600;
}

/* No groups message */
.no-groups {
    text-align: center;
    color: #999;
}

.group-details {
    display: grid;
}
