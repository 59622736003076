.login-page {
    display: flex;
    height: 100%;
}

.login-bg {
    display: flex;
    width: 66.7%;
    object-fit: cover;
}

.login-area {
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 33.3%;
    padding: 80px 70px;
    align-items: center;
}
