.add-side-budget-modal .buttons-wrapper {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
}

.add-side-budget-modal {
    width: 100%;
}

.add-side-budget-step-1 {
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 27px;

    .add-budget-footer {
        display: flex;
        flex-direction: column;
        gap: 32px;
        color: var(--title-grey-color);
        font-size: 14px;
        line-height: 1.5;
    }
}

.add-side-budget-step-1 .insert-verification-code {
    text-align: right;
}

.add-side-budget-step-2 {
    text-align: right;
    width: 378px;
    box-sizing: border-box;
}
