.modal {
    position: fixed;
    display: flex;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 800px;
    max-width: 1000px;
    height: auto;
    width: auto;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 6px;
    background: var(--white);
    box-sizing: border-box;
    box-shadow: 0 4px 15px 0 rgba(44, 63, 88, 0.35);
    z-index: 32;

    .close-icon {
        position: absolute;
        display: flex;
        top: -5px;
        left: -5px;
        width: 34px;
        height: 34px;
        box-shadow: 0 4px 15px 0 rgba(44, 63, 88, 0.35);
        border-radius: 6px;
        background: var(--white);
        border: none;
    }
}

.modal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}
