.related-groups-container {
    display: flex;
    align-items: center;

    .related-group {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        border: 2px solid white;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        &.more-count {
            background-color: #ff6f6f;
            color: white;
            font-size: 16px;
            font-weight: bold;
        }

        .image-container {
            width: inherit;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            background-color: #f0f0f0;
            border-radius: 50%;
            background-size: cover;
            background-position: center;

            .acronym {
                position: absolute;
                font-size: 12px;
                font-weight: bold;
                color: white;
                text-transform: uppercase;
                z-index: 1;
            }
        }
    }

    .label {
        font-size: 14px;
        color: #333;
        font-weight: 400;
    }
}
