.create-post {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;

    .current-step-container {
        display: flex;
        gap: 32px;
        overflow: auto;
        height: 300px;
        padding: 12px;

        .post-steps-tabs {
            display: flex;
            flex-direction: column;
            gap: 32px;
            width: 157px;
            box-sizing: border-box;
        }

        .create-post-step {
            display: flex;
            gap: 12px;

            .step-icon {
                width: 38px;
                height: 38px;
                border-radius: 6px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                background-color: rgba(217, 217, 218, 0.12);
            }

            .create-form-step-title {
                display: flex;
                flex-direction: column;
                justify-content: center;

                .step-main-title {
                    color: var(--title-grey-color);
                    font-size: 14px;
                    font-weight: 600;
                    margin: 0;
                }

                .step-sub-title {
                    font-size: 12px;
                    margin: 0;
                    color: var(--color-placeholder-text);
                }
            }

            .current-step-step-content {
                display: flex;
                flex-direction: column;
            }
        }
    }

    .not-enough-budget {
        background-color: var(--color-error-background);
        color: var(--red);
        border-radius: 20px;
        padding: 12px;
        display: flex;
        align-items: center;
    }

    .budget-amount {
        background-color: var(--primary);
        color: var(--white);
        border-radius: 20px;
        padding: 12px;
        display: flex;
        align-items: center;
    }

    .bottom-messages {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
}

.event-gift {
    display: flex;
    flex-direction: column;
    gap: 24px;
}
